import * as React from "react";
import { useState } from "react";
import styled from "styled-components";
import Layout from "../../../../components/Layout/Layout.js";
import useContentful from "../../../../utils/useContentful";
import useContentful2 from "../../../../utils/useContentful2";
import BoldContentful from "../../../../utils/BoldContentful.jsx";
import { Link } from "gatsby";
import MetaTags from "react-meta-tags";
import "../../../../styles/global.css";
import "../../../../styles/breadcrums.css";
import { Helmet } from "react-helmet";
import Home from "../../../../images/Home/home.png";
import Titlerich from "../../../../components/Titlerich.js";
import Sideimage from "../../../../components/proyectos/pais/Sideimage.js";
import Historianorich from "../../../../components/proyectos/pais/crecer/Historianorich.js";
import Logos from "../../../../components/proyectos/pais/aprendizaje/Logos.js";
import Slidercrecer from "../../../../components/proyectos/pais/crecer/Slidercrecer.js";
import Textrichaprendo from "../../../../components/proyectos/pais/aprendizaje/Textrichaprendo.js";
import Textrichaprendotitle from "../../../../components/proyectos/pais/aprendizaje/Textrichaprendotitle.js";
import Alcance from "../../../../images/Home/alcance_celeste.svg";

const LogrosRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: start;
  width: 90%;
`;

const ImgAlcance = styled.img`
  width: 120px;
  height: auto;
`;

const LogrosColumns = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  @media (max-width: 900px) {
    display: none;
  }
`;

const BulletDot = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 3%;
  font-size: 32px;
  color: #9e9e9e;
  @media (max-width: 900px) {
    font-size: 24px;
    margin: 0 1rem 0 0;
  }
`;

const LogrosColumnsResponsive = styled.div`
  display: none;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media (max-width: 900px) {
    display: flex;
  }
`;

const LogrosItem = styled.div`
  height: auto;
  width: 80%;
  margin: 0 0 0 0;
  text-align: justify;
  font-size: 26px;
  color: #9e9e9e;
  @media (max-width: 900px) {
    height: auto;
    width: 80%;
    margin: 0 0 0 0;
    font-size: 16px;
  }
`;

const Container = styled.div`
  position: relative;
  width: 100%;
  /*max-width: 1440px;*/
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  margin-top: 78px;
`;

const TextoAct = styled.div`
  width: 90%;
  max-width: 1440px;
  color: var(--gris);
  padding: 0px 5%;
  padding-top: 2%;
  padding-bottom: 4%;
  margin-top: 0%;
  margin-bottom: 0%;
  margin-left: 5%;
  margin-right: 5%;
  text-align: left;
  font-size: 22px;
`;

const TituloAct = styled.div`
  width: 100%;
  max-width: 1440px;
  font-size: 38px;
  color: var(--verde);
  font-weight: 700;
  padding: 0px 0%;
  margin-top: 6%;
  margin-bottom: 0%;
  text-align: left;
`;

const Contentbar = styled.div`
  display: flex;
  flex-direction: column;
  padding: 5%;
  img {
    width: 160px;
    height: 100px;
  }
`;

const Cardcontainer = styled.div`
  padding: 0px 3%;
  display: flex;
  flex-direction: column;
  height: 100%;
  align-items: center;

  img {
    width: 80%;
  }
  @media (max-width: 900px) {
    padding: 0px 0%;

    img {
      width: 100%;
      margin-bottom: 30px;
    }
  }
`;

const Breadcrumbs = styled.div`
  display: flex;
  flex-direction: row
  justify-content: center;
  align-items: center;
  font-size: 16px;
  font-weight: bold;
  width: 90%;
`;

const Homeimg = styled.img`
  width: 32px;
`;

const GeneralEntryId = "8zG9dMMm9VFjkRLfm9y3W";
const AprendoEntry = "4GiVqTYrs5ynw2ev8sARx5";

const IndexPage = ({ location }) => {
  const { data: aprendo, fetched } = useContentful2({ id: AprendoEntry });
  const { data: general, fetched: fetched2 } = useContentful({
    id: GeneralEntryId,
  });

  return (
    <>
      {fetched && fetched2 ? (
        <>
          <MetaTags>
            <title>Redes de Aprendizaje</title>
            <meta name="description" content="Redes de Aprendizaje" />
            <meta name="robots" content="index,follow" />
            <link
              rel="canonical"
              href="https://www.aporta.org.pe/proyectos/pais/aprendizaje/"
            />
            <link
              rel="alternate"
              hrefLang="x-default"
              href="https://www.aporta.org.pe/proyectos/pais/aprendizaje/"
            />
            <link
              rel="alternate"
              hrefLang="es-PE"
              href="https://www.aporta.org.pe/proyectos/pais/aprendizaje/"
            />
          </MetaTags>
          <Helmet>
            <script type="application/ld+json">
              {JSON.stringify({
                "@context": "https://schema.org/",
                "@type": "BreadcrumbList",
                itemListElement: [
                  {
                    "@type": "ListItem",
                    position: 1,
                    name: "Aporta",
                    item: "https://www.aporta.org.pe/",
                  },
                  {
                    "@type": "ListItem",
                    position: 2,
                    name: "Proyectos",
                    item: "https://www.aporta.org.pe/proyectos/",
                  },
                  {
                    "@type": "ListItem",
                    position: 3,
                    name: `Pais`,
                    item: `https://www.aporta.org.pe/proyectos/pais/`,
                  },
                  {
                    "@type": "ListItem",
                    position: 4,
                    name: `Redes de Aprendizaje`,
                    item: `https://www.aporta.org.pe/proyectos/pais/aprendizaje`,
                  },
                ],
              })}
            </script>
          </Helmet>
          <Layout
            margen={false}
            direccion={general.fields.direccion}
            facebook={general.fields.facebook}
            instagram={general.fields.instagram}
            linkedin={general.fields.linkedin}
            email={general.fields.email}
            telefono={general.fields.telefono}
            youtube={general.fields.youtube}
          >
            <Container>
              <br></br>
              <Breadcrumbs>
                <Link
                  to="/"
                  className={
                    location.pathname.startsWith("/proyectos")
                      ? "breadcrumb-active"
                      : "breadcrumb-not-active"
                  }
                  style={{ textDecoration: "none" }}
                >
                  <Homeimg src={Home} alt="homeimage" />
                </Link>
                <span
                  className="breadcrumb-arrow"
                  style={{ color: "var(--celeste)" }}
                >
                  &gt;
                </span>
                <Link
                  to="/proyectos"
                  className={
                    location.pathname.startsWith("/proyectos")
                      ? "breadcrumb-active"
                      : "breadcrumb-not-active"
                  }
                  style={{ textDecoration: "none" }}
                >
                  Proyectos
                </Link>
                <span
                  className="breadcrumb-arrow"
                  style={{ color: "var(--celeste)" }}
                >
                  &gt;
                </span>
                <Link
                  to="/proyectos/pais"
                  className={
                    location.pathname.startsWith("/proyectos")
                      ? "breadcrumb-active"
                      : "breadcrumb-not-active"
                  }
                  style={{ textDecoration: "none" }}
                >
                  Innovación Social País
                </Link>
                <span
                  className="breadcrumb-arrow"
                  style={{ color: "var(--celeste)" }}
                >
                  &gt;
                </span>
                <Link
                  to="/proyectos/pais/aprendizaje"
                  className={"breadcrumb-not-active"}
                  style={{ textDecoration: "none" }}
                >
                  Redes de Aprendizaje
                </Link>
              </Breadcrumbs>
              <br></br>
              <Titlerich
                color={"var(--celeste"}
                colortext={"var(--negro)"}
                container={aprendo.fields.tituloRich}
                colorbold={"var(--negro"}
              ></Titlerich>
              <br></br>
              <Sideimage
                image={aprendo.fields.aprendizajeRow1image.fields.file.url}
                colorb={"var(--celeste)"}
              >
                <Contentbar>{aprendo.fields.aprendizajeRow1text}</Contentbar>
              </Sideimage>
              <br></br>
              <Historianorich
                title={aprendo.fields.componentesTitle}
                texto={aprendo.fields.componentesText}
                fontc={"var(--morado)"}
                fontcb={"var(--morado)"}
              >
                <Cardcontainer>
                  <img
                    src={aprendo.fields.componentesMedia.fields.file.url}
                    alt="aprendo"
                  />
                </Cardcontainer>
              </Historianorich>
              <br></br>
              <br></br>
              <br></br>
              <ImgAlcance src={Alcance} alt="aprendizaje_alcance" />
              <Textrichaprendotitle
                texto={aprendo.fields.alcanceTitulo}
                clr={"var(--celeste)"}
              ></Textrichaprendotitle>
              <Textrichaprendo
                texto={aprendo.fields.alcance}
                clr={"var(--celeste)"}
              ></Textrichaprendo>

              <TituloAct>{aprendo.fields.logrostitle}</TituloAct>
              <br></br>
              <br></br>
              <LogrosColumns>
                {aprendo.fields.logrosText1.content[0].content.map(
                  (item, index) => {
                    return (
                      <>
                        <LogrosRow>
                          <BulletDot>•</BulletDot>
                          <LogrosItem key={index}>
                            {BoldContentful(
                              item.content[0],
                              `var(--${aprendo.fields.coloresColumna1[index]})`,
                              "34px"
                            )}
                          </LogrosItem>
                        </LogrosRow>
                        <br></br>
                      </>
                    );
                  }
                )}
              </LogrosColumns>
              {/*<LogrosColumns>
                {aprendo.fields.logrosText1.content[0].content.map(
                  (item, index) => {
                    return (
                      <LogrosRow>
                        <LogrosItem key={index}>
                          {BoldContentful(
                            item.content[0],
                            aprendo.fields.coloresColumna1[index].replaceAll(
                              "/[/]/g",
                              ""
                            ),
                            "24px"
                          )}
                        </LogrosItem>

                        {aprendo.fields.logrosText2.content[0].content.map(
                          (item2, index2) => {
                            return (
                              <>
                                {index == index2 ? (
                                  <LogrosItem key={index2}>
                                    {BoldContentful(
                                      item2.content[0],
                                      aprendo.fields.coloresColumna2[
                                        index
                                      ].replaceAll("/[/]/g", ""),
                                      "24px"
                                    )}
                                  </LogrosItem>
                                ) : (
                                  <></>
                                )}
                              </>
                            );
                          }
                        )}
                      </LogrosRow>
                    );
                  }
                )}
              </LogrosColumns>*/}
              <LogrosColumnsResponsive>
                {aprendo.fields.logrosText1.content[0].content.map(
                  (item, index) => {
                    return (
                      <>
                        <LogrosRow>
                          <BulletDot>•</BulletDot>
                          <LogrosItem key={index}>
                            {BoldContentful(
                              item.content[0],
                              `var(--${aprendo.fields.coloresColumna1[index]})`,
                              "24px"
                            )}
                          </LogrosItem>
                        </LogrosRow>
                        <br></br>
                      </>
                    );
                  }
                )}
              </LogrosColumnsResponsive>
              <br></br>
              <Logos cards={aprendo.fields.logoEmpresas}></Logos>
              <Slidercrecer galeria={aprendo.fields.galeria}></Slidercrecer>
              <br></br>
            </Container>
          </Layout>
        </>
      ) : (
        <div
          style={{
            width: "100vw",
            height: "100vh",
            display: "flex",
            justifyContent: "center",
            marginTop: "0px",
            padding: "10%",
          }}
        >
          {" "}
          <svg
            version="1.1"
            id="L9"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 100 100"
            enableBlackground="new 0 0 0 0"
            xmlSpace="preserve"
          >
            <path
              fill="var(--celeste)"
              d="M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50"
            >
              <animateTransform
                attributeName="transform"
                attributeType="XML"
                type="rotate"
                dur="1s"
                from="0 50 50"
                to="360 50 50"
                repeatCount="indefinite"
              />
            </path>
          </svg>
        </div>
      )}
    </>
  );
};

export default IndexPage;
